
<template>
    <div class="platform-bottom-layout">
        <div class="platform-buttom-list">
          <div :class="listType===1? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(1)">合同收付列表</div>
          <div :class="listType===2? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(2)">收付款明细</div>
          <div :class="listType===3? 'bottom-button-active':'bottom-button'" @click="handleChangeTag(3)">开票明细</div>
        </div>
        <component :is="currentComponent + 'Component'" ref="bottom" @on-showDetail="showEntryDetail" @on-edit="entryPayment"/>
    </div>
</template>

<script>
export default {
  components: {
    contractComponent: (resolve) => {
      require(['./bottom/Contract'], resolve)
    },
    paymentComponent: (resolve) => {
      require(['./bottom/Payment'], resolve)
    },
    invoicingComponent: (resolve) => {
      require(['./bottom/Invoicing'], resolve)
    }
  },
  props: {
  },
  data () {
    return {
      listType: 1,
      currentComponent: 'contract'
    }
  },
  methods: {
    handleChangeTag (val) {
      this.listType = val

      this.$store.commit('set_payment_showLeft', false)
      // this.$store.commit('set_payment_showRight', false)
      if (val === 1) {
        this.currentComponent = 'contract'
        // this.$store.commit('set_payment_showRight', true)
      } else if (val === 2) {
        this.currentComponent = 'payment'
      } else if (val === 3) {
        this.currentComponent = 'invoicing'
      }
    },
    reload () {
      this.$refs.bottom.reload()
    },
    showEntryDetail (item) {
      this.$emit('on-showLeft', item, false)
    },
    entryPayment (item) {
      this.$emit('on-showLeft', item, true)
    }
  },
  watch: {
  }
}
</script>
